import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from 'react-share';
import moment from 'moment';
import mixpanel from 'mixpanel-browser';
import RatingStars from '../helpers/starGenerator';
import BasicEditEventModal from './BasicEditEventModal';
import { ReactComponent as BackIcon } from '../../images/icons/chevron_left.svg';
import { ReactComponent as CopyIcon } from '../../images/icons/copy.svg';
import { ReactComponent as TwitterIcon } from '../../images/twitter_logo_white.svg';
import { ReactComponent as FacebookIcon } from '../../images/facebook_logo_blue.svg';
import { ReactComponent as WhatsappIcon } from '../../images/whatsapp_logo_white2.svg';
import { ReactComponent as EmailIcon } from '../../images/icons/email.svg';
import { ReactComponent as ChevronRight } from '../../images/icons/chevron-right.svg';
import { ReactComponent as EditDateIcon } from '../../images/pen.svg';

import EventLabel from './EventLabel';
import AppContext from '../app/AppContext';

class EventCard extends Component {
     static contextType = AppContext;

     constructor (props) {
         super(props);

         this.state = {
             activeTooltip: null,
             editEventModalOpen: false,
             shareButtonsOpen: false,
         };
     }

    toggleEditEventModal = (dataChanged, eventId, newDate) => {
        const { editEventModalOpen } = this.state;
        editEventModalOpen
            ? this.setState(
                {
                    editEventModalOpen: false,
                },
                () => {
                    if (dataChanged) {
                        this.props.updateEventsLists(eventId, newDate, false);
                    }
                }
            )
            : this.setState({
                editEventModalOpen: true,
            });
    };

    toggleShareButtons = () => {
        // const { shareButtonsOpen } = this.state;
        // shareButtonsOpen
        //     ? this.setState({
        //         shareButtonsOpen: false,
        //     })
        //     : this.setState({
        //         shareButtonsOpen: true,
        //     });
    };

    handleTooltipMouseIn = e => {
        this.setState({
            activeTooltip: e.target.getAttribute('name'),
        });
    };

    handleTooltipMouseOut = () => {
        this.setState({
            activeTooltip: null,
        });
    };

    handleCopy = () => {
        this.setState(
            {
                activeTooltip: 'copied',
            },
            () => {
                navigator.clipboard.writeText(`https://racecheck.com/races/${this.props.event.slug}/`);
            }
        );
    };

    redirectToReviews = () => {
        if (this.props.event.reviews) {
            this.props.history.push(`/events/${this.props.event.id}/reviews`);
        }
        else {
            this.props.history.push(`/events/${this.props.event.id}/request-reviews`);
        }
    };

    handleEventNameClick = (mpProps) => {
        mixpanel.track('Dashboard - Race card - Event name click', mpProps);
        this.redirectToReviews();
    };

    render () {
        const { activeTooltip, editEventModalOpen, shareButtonsOpen } = this.state;
        const mpProps = this.context.selectedOrganisation ? {
            organisation: this.context.selectedOrganisation?.name,
            premium_subscriber: this.context.selectedOrganisation?.is_premium_subscriber
        } : {};

        return (
            <>
                <div className="event-card-container">
                    {this.props.event.id && this.props.event.remaining_tasks > 0 &&
                        <Link
                            to={`/events/${this.props.event.id}/request-reviews`}
                            className={`event-header-tasks-notification
                             ${this.props.event.remaining_tasks > 1 ? 'multiple-tasks' : ''}`}
                            onClick={() => {
                                mixpanel.track('Dashboard - Race card - Remaining tasks click', mpProps);
                            }}
                        >
                            <p>
                                {this.props.event.remaining_tasks}
                                <span>
                                    {' '}task{this.props.event.remaining_tasks > 1 ? 's' : ''} remaining →
                                </span>
                            </p>
                        </Link>
                    }
                    <div className="event-card-btn-container">
                        {shareButtonsOpen ? (
                            <div className="event-card-share-list">
                                <div className="ecsl-back" onClick={this.toggleShareButtons}>
                                    <BackIcon />
                                </div>
                                <div className="event-card-btn-separator" />
                                <div className="ecsl-icon-container">
                                    <div
                                        className="escl-social-icon escl-copy"
                                        name="copy"
                                        onMouseEnter={this.handleTooltipMouseIn}
                                        onMouseLeave={this.handleTooltipMouseOut}
                                        onClick={this.handleCopy}
                                    >
                                        <CopyIcon />
                                    </div>
                                    {activeTooltip === 'copy' && (
                                        <div className="ecsl-popup ecsl-popup-copy">
                                            <p>Copy link</p>
                                        </div>
                                    )}
                                    {activeTooltip === 'copied' && (
                                        <div className="ecsl-popup ecsl-popup-copied">
                                            <p>Link copied</p>
                                        </div>
                                    )}
                                </div>
                                <div className="ecsl-icon-container">
                                    <div
                                        className="escl-social-icon escl-email"
                                        name="email"
                                        onMouseEnter={this.handleTooltipMouseIn}
                                        onMouseLeave={this.handleTooltipMouseOut}
                                    >
                                        <EmailShareButton
                                            subject={this.props.event.title}
                                            body={`Check out what past participants 
                                            are saying about ${this.props.event.title} on Racecheck - `}
                                            url={`https://racecheck.com/races/${this.props.event.slug}`}
                                            name="email"
                                            onMouseEnter={this.handleTooltipMouseIn}
                                            onMouseLeave={this.handleTooltipMouseOut}
                                        >
                                            <EmailIcon />
                                        </EmailShareButton>
                                    </div>
                                    {activeTooltip === 'email' && (
                                        <div className="ecsl-popup ecsl-popup-email">
                                            <p>Share via Email</p>
                                        </div>
                                    )}
                                </div>
                                <div className="ecsl-icon-container">
                                    <div
                                        className="escl-social-icon escl-twitter"
                                        name="twitter"
                                        onMouseEnter={this.handleTooltipMouseIn}
                                        onMouseLeave={this.handleTooltipMouseOut}
                                    >
                                        <TwitterShareButton
                                            title={`Check out what past participants 
                                            are saying about ${this.props.event.title} on @racecheck!`}
                                            url={`https://racecheck.com/races/${this.props.event.slug}`}
                                            name="twitter"
                                            onMouseEnter={this.handleTooltipMouseIn}
                                            onMouseLeave={this.handleTooltipMouseOut}
                                        >
                                            <TwitterIcon />
                                        </TwitterShareButton>
                                    </div>
                                    {activeTooltip === 'twitter' && (
                                        <div className="ecsl-popup ecsl-popup-twitter">
                                            <p>Share to Twitter</p>
                                        </div>
                                    )}
                                </div>
                                <div className="ecsl-icon-container">
                                    <div
                                        className="escl-social-icon escl-facebook"
                                        name="facebook"
                                        onMouseEnter={this.handleTooltipMouseIn}
                                        onMouseLeave={this.handleTooltipMouseOut}
                                    >
                                        <FacebookShareButton
                                            url={`https://racecheck.com/races/${this.props.event.slug}`}
                                            name="facebook"
                                            onMouseEnter={this.handleTooltipMouseIn}
                                            onMouseLeave={this.handleTooltipMouseOut}
                                        >
                                            <FacebookIcon />
                                        </FacebookShareButton>
                                    </div>
                                    {activeTooltip === 'facebook' && (
                                        <div className="ecsl-popup ecsl-popup-facebook">
                                            <p>Share to Facebook</p>
                                        </div>
                                    )}
                                </div>
                                <div className="ecsl-icon-container">
                                    <div
                                        className="escl-social-icon escl-whatsapp"
                                        name="whatsapp"
                                        onMouseEnter={this.handleTooltipMouseIn}
                                        onMouseLeave={this.handleTooltipMouseOut}
                                    >
                                        <WhatsappShareButton
                                            title={`Check out what past participants are 
                                            saying about ${this.props.event.title} on Racecheck!`}
                                            url={`https://racecheck.com/races/${this.props.event.slug}`}
                                            name="whatsapp"
                                            onMouseEnter={this.handleTooltipMouseIn}
                                            onMouseLeave={this.handleTooltipMouseOut}
                                        >
                                            <WhatsappIcon />
                                        </WhatsappShareButton>
                                    </div>
                                    {activeTooltip === 'whatsapp' && (
                                        <div className="ecsl-popup ecsl-popup-whatsapp">
                                            <p>Share via WhatsApp</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="event-card-btns-list">
                                <Link 
                                    to={this.props.event.id ? `/events/${this.props.event.id}/widgets` : '#'}
                                    className={`event-card-btn ${!this.props.event.id && 'disabled'}`}
                                    onClick={() => {
                                        this.props.event.id &&
                                        mixpanel.track('Dashboard - Race card - Widgets click', mpProps);
                                    }}
                                >
                                    Widgets
                                </Link>
                                <div className="event-card-btn-separator" />
                                <Link 
                                    to={this.props.event.reviews ? `/events/${this.props.event.id}/analytics` : '#'}
                                    className={`event-card-btn ${!this.props.event.reviews && 'disabled'}`}
                                    onClick={() => {
                                        this.props.event.reviews &&
                                        mixpanel.track('Dashboard - Race card - Analytics click', mpProps);
                                    }}
                                >
                                    Analytics
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className="event-card">
                        <div className="event-header-wrapper">
                            <div className={`event-header-img ${!this.props.event.id && 'event-header-img-pending'}`}>
                                <img alt="event-header" src={this.props.event.main_image} />
                            </div>
                            <EventLabel award={this.props.event.latest_award} />
                        </div>
                        <div className="event-info">
                            <p
                                className={`event-title ${!this.props.event.id && 'cursor-default'}`}
                                onClick={() => {
                                    this.props.event.id &&
                                    this.handleEventNameClick(mpProps);
                                }
                                }
                            >
                                {this.props.event.title}
                                {this.props.event.id &&
                                    <span><ChevronRight /></span>
                                }
                            </p>
                            <div className="event-info-bottom">
                                {this.props.event.formatted_latest_date 
                                    ? (
                                        <div className="event-date-container" onClick={() => {
                                            mixpanel.track('Dashboard - Race card - Update event date click', mpProps);
                                            this.toggleEditEventModal();
                                        }}
                                        >
                                            {moment(this.props.event.latest_date).isSameOrAfter(new Date()) ?
                                                <>
                                                    <p className="event-date">{this.props.event.formatted_latest_date}</p>
                                                    <div>
                                                        <EditDateIcon className="edit-event-date" />
                                                    </div>
                                                </>
                                                :
                                                <button 
                                                    className="event-date-btn"
                                                    /* eslint-disable */
                                                    onClick={() => {
                                                        mixpanel.track('Dashboard - Race card - Update event date click', mpProps);
                                                        this.toggleEditEventModal();
                                                    }}
                                                    /* eslint-disable */
                                                >
                                                Update event date
                                                </button>
                                            }
                                        </div>
                                    )
                                    :
                                    <>
                                        {this.props.event.id &&
                                            <button
                                                className="event-date-btn"
                                                onClick={() => {
                                                    mixpanel.track('Dashboard - Race card - Update event date click', mpProps);
                                                    this.toggleEditEventModal
                                                }}
                                            >
                                                Add event date
                                            </button>
                                        }
                                    </>
                                }
                                <p className="event-location">{this.props.event.address}</p>
                                {/* <img
                                    className="event-distance-icon"
                                    alt="event-distance-icon"
                                    src={this.props.event.distances.icon}
                                />
                                <p className="event-distances">{this.props.event.distances.value}</p> */}
                                {this.props.event.id ?
                                    <div>
                                        {+this.props.event.reviews > 0 ? (
                                            <div className="event-card-stars" onClick={() => {
                                                mixpanel.track('Dashboard - Race card - Stars click', mpProps);
                                                this.redirectToReviews();
                                            }}
                                            >
                                                <RatingStars rating={parseFloat(this.props.event.average_rating)} />
                                                &nbsp;
                                                <div className="event-card-total">({this.props.event.reviews})</div>
                                            </div>
                                        ) : (
                                            <Link
                                                to={`/events/${this.props.event.id}/request-reviews`}
                                                className="event-card-start-collecting"
                                            >
                                                Start collecting reviews
                                            </Link>
                                        )}
                                    </div>
                                    :
                                    <div className="event-card-pending">
                                        <p>We are currently setting up your event. Check back soon.</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {editEventModalOpen && (
                    // <EditEventModal toggleModal={this.toggleEditEventModal} event={this.props.event}/>
                    <BasicEditEventModal toggleModal={this.toggleEditEventModal} event={this.props.event} />
                )}
            </>
        );
    }
}

EventCard.propTypes = {
    event: PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        slug: PropTypes.string,
        main_image: PropTypes.string,
        formatted_latest_date: PropTypes.string,
        latest_date: PropTypes.string,
        address: PropTypes.string,
        distances: PropTypes.shape({
            icon: PropTypes.string,
            value: PropTypes.string,
        }),
        average_rating: PropTypes.string,
        widget: PropTypes.shape({
            status: PropTypes.string,
            id: PropTypes.number
        }),
        reviews: PropTypes.number,
        status: PropTypes.string,
        remaining_tasks: PropTypes.number,
        latest_award: PropTypes.shape({
            banner_color: PropTypes.string,
            banner_text: PropTypes.string,
        })
    }).isRequired,
    showingCompleted: PropTypes.bool,
    updateEventsLists: PropTypes.func,
    latest_award: PropTypes.shape({
        banner_text: PropTypes.string,
        banner_color: PropTypes.string,
    })
};

EventCard.defaultProps = {
    event: {
        id: '',
        title: '',
        slug: '',
        main_image: '',
        formatted_latest_date: '',
        latest_date: '',
        address: '',
        distances: {
            icon: '',
            value: '',
        },
        average_rating: '',
    },
    updateEventsLists: () => {},
};

export default withRouter(EventCard);
